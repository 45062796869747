<template>
  <div class="container">
    <PublicHeader
      :totalName="totalName"
      :rightTitle="rightTitle"
      rightRouter="NewBeHistory"
    />
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleClick(item)"
        >
          <div class="title">
            <div class="name">{{ item.Name }}</div>
            <div class="day">
              {{ $t('newBe').scqx }}:
              <span>{{ item.LockTime }} {{ $t('newBe').day }}</span>
            </div>
          </div>
          <div class="line">
            <div class="left">
              <span>{{ item.Circulation }} {{ item.Name }} / </span>
              <span>{{ item.Surplus }}</span>
            </div>
            <div class="right">
              {{ $t('newBe').sy }}
            </div>
          </div>
          <div class="line">
            <div class="left">
              {{ item.Name }}
            </div>
            <div class="right">{{ item.percentage }}%</div>
          </div>
          <van-progress
            :show-pivot="false"
            :percentage="item.percentage"
            stroke-width="8"
          />
        </div>
      </van-list>
    </div>
    <div class="overlay" v-if="show">
      <div class="box">
        <div class="title">
          {{ info.Name }}
        </div>
        <div class="line">
          <span>{{ $t('newBe').sysl }}</span>
          <span>{{ info.Surplus }}</span>
        </div>
        <div class="line">
          <span>{{ $t('newBe').dj }}</span>
          <span>{{ info.Price.toFixed(4) }} USDT</span>
        </div>
        <div class="line">
          <span>{{ $t('newBe').gmsl }}</span>
          <van-field
            v-model="info.Num"
            input-align="right"
            type="number"
            :placeholder="$t('newBe').gmsl"
          />
        </div>
        <div class="line">
          <span>{{ $t('newBe').gmzj }}</span>
          <span
            >{{ info.Num ? (info.Num * info.Price).toFixed(4) : '0.0000' }}
            USDT
          </span>
        </div>
        <div class="line">
          <span>{{ $t('newBe').qbye }}</span>
          <span>{{ USDT }} USDT</span>
        </div>
        <div class="btns">
          <div class="btn" @click="show = false">{{ $t('public').cancel }}</div>
          <div class="btn" @click="handleSub">{{ $t('newBe').gm }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import productApi from '@/api/product'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      totalName: this.$t('newBe').title,
      rightTitle: this.$t('newBe').sdsg,
      list: [],
      form: {
        page: 1,
        size: 10
      },
      USDT: '',
      info: {},
      show: false,
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    handleClick(item) {
      if (item.Surplus === 0) {
        return
      }
      this.info = item
      this.show = true
    },
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.USDT = res.LegalAmountInfo.USDT
    },
    async handleSub() {
      const BuyNum = Number(this.info.Num)
      if (!BuyNum) {
        this.$toast({
          position: 'bottom',
          message: this.$t('newBe').tips
        })
        return
      }
      const form = {
        ProductId: this.info.Id,
        BuyNum
      }
      await productApi.createNewCoinOrder(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.push({ name: 'NewBeHistory' })
      }, 1000)
    },
    async getList() {
      const res = await productApi.getNewCoin(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        data.forEach(item => {
          item.percentage = ((item.Surplus / item.Circulation) * 100).toFixed(2)
          item.Num = ''
        })
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 50px;

  .content {
    color: #8897ad;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .item {
      background: #17181e;
      border-radius: 4px;
      margin: 10px;
      padding: 15px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          color: #fff;
          font-size: 16px;
          // font-weight: bold;
        }
        .day {
          font-size: 12px;
          span {
            color: #ee7625;
          }
        }
      }
      .line {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          span:nth-of-type(1) {
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 90%;
      background: #fff;
      border-radius: 4px;
      .title {
        font-size: 20px;
        color: #000;
        text-align: center;
        line-height: 45px;
        border-bottom: 1px solid #ddd;
      }
      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 14px;
        height: 57px;
        color: #999;
        span:nth-of-type(2) {
          font-weight: bold;
          color: #000;
        }
        .van-field {
          width: 50%;
          background: #eee;
        }
      }
      .btns {
        padding: 20px 10px 10px;
        display: flex;
        justify-content: space-between;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 50px;
          background-color: #eee;
          color: #999;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          &:nth-of-type(2) {
            border: 0;
            background-color: #0066ed;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
